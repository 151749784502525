var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "size": "xl",
      "title": _vm.modalTitle
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('hr'), _c('div', {
          staticClass: "form-row d-flex justify-content-end"
        }, [_c('b-button', {
          staticClass: "m-1",
          attrs: {
            "variant": "outline-dark",
            "disabled": !_vm.availableBottlings.length || !_vm.selectedBottlings.length
          },
          on: {
            "click": function click($event) {
              return _vm.select();
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "check"
          }
        }), _vm._v(" Select ")], 1)], 1), _c('div', {
          staticClass: "form-row d-flex justify-content-end"
        }, [_c('b-button', {
          staticClass: "m-1",
          attrs: {
            "variant": "outline-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.close();
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "times"
          }
        }), _vm._v(" Close ")], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('TableWrapper', {
    ref: _vm.model.name,
    attrs: {
      "loading": _vm.isLoading,
      "actions-mode": "event",
      "model": _vm.model,
      "filter-by-column": true,
      "per-page": 10,
      "columns": _vm.columns,
      "cell-classes": _vm.cellClasses
    },
    on: {
      "updated": _vm.onBottlingsTableUpdate,
      "loaded": _vm.onBottlingsTableLoad
    }
  }), _c('hr'), this.bottlesRequired ? _c('div', [_c('strong', [_vm._v("Bottles required:")]), _vm._v(" " + _vm._s(this.bottlesRequired) + " ")]) : _vm._e(), _c('div', [_c('strong', [_vm._v("Bottles selected:")]), _vm._v(" " + _vm._s(this.bottlesSelected))])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }