<template>
  <b-modal ref="modal" centered scrollable size="xl" :title="modalTitle">
    <TableWrapper
      :ref="model.name"
      :loading="isLoading"
      actions-mode="event"
      :model="model"
      :filter-by-column="true"
      :per-page="10"
      :columns="columns"
      :cell-classes="cellClasses"
      @updated="onBottlingsTableUpdate"
      @loaded="onBottlingsTableLoad"
    ></TableWrapper>
    <hr />
    <div v-if="this.bottlesRequired">
      <strong>Bottles required:</strong> {{ this.bottlesRequired }}
    </div>
    <div><strong>Bottles selected:</strong> {{ this.bottlesSelected }}</div>

    <template #modal-footer="">
      <hr />
      <div class="form-row d-flex  justify-content-end">
        <b-button
          variant="outline-dark"
          class="m-1"
          @click="select()"
          :disabled="!availableBottlings.length || !selectedBottlings.length"
        >
          <font-awesome-icon icon="check" /> Select
        </b-button>
      </div>

      <div class="form-row d-flex  justify-content-end">
        <b-button variant="outline-dark" class="m-1" @click="close()">
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import TableWrapper from "@/components/DataView/TableWrapper";
import models from "@/models";
import warehouseServices from "@/services/warehouse.service";

export default {
  components: { TableWrapper },

  data() {
    return {
      isLoading: false,
      model: models.warehouse.bottlingsSearch,
      columns: models.warehouse.bottlingsSearch.entities.map(e => e.name),
      cellClasses: {
        "Bottles Selected": [
          {
            class: "bg-success",
            condition: row => +row["Bottles Selected"] > 0
          }
        ]
      },
      availableBottlings: [],
      searchConditions: undefined,
      bottlesRequired: 0,
      bottlesSelected: 0,
      selectedBottlings: []
    };
  },
  computed: {
    modalTitle() {
      if (!this.searchConditions) return "";

      let title = [];

      let c = this.searchConditions;

      if (c["Warehouse"]) title.push(c["Warehouse"]);
      if (c["Finished Product"]) title.push(c["Finished Product"]);
      if (c["Bottle Size"]) title.push(c["Bottle Size"]);
      if (c["Organic Status"]) title.push(c["Organic Status"]);

      return title.join();
    }
  },

  methods: {
    show(payload) {
      //if called from invoice then allow to edit case prices
      //if called from picklist then prices readonly
      let idx = this.model.entities.findIndex(e => e.name == "Cost per Case");

      if (!payload["Finished Product"]) {
        this.model.entities[idx].readonly = false;
        this.model.entities[idx].required = true;
      } else {
        this.model.entities[idx].readonly = true;
        this.model.entities[idx].required = false;
      }

      this.searchConditions = payload;
      this.bottlesSelected = 0;
      this.bottlesRequired = this.searchConditions["Bottles Difference"];

      this.fetchData();

      this.$refs.modal.show();
    },
    close() {
      this.$refs.modal.hide();

      this.$emit("close");
    },
    async select() {
      let confirm = await this.$form.showConfirmation(
        `The ${this.bottlesSelected} bottles of selected product(s) will be subtracted from bottlings records. Do you want to proceed?`
      );

      if (!confirm) return;

      this.$refs.modal.hide();

      this.$emit("select", this.selectedBottlings);
    },

    async fetchData() {
      this.isLoading = true;

      let result = await warehouseServices.fetchAvailableBottlings(
        this.searchConditions
      );

      this.isLoading = false;

      if (result.length) {
        result.forEach(i => {
          i["Cost per Case"] = this.searchConditions["Cost per Case"];
          i["Bottles Selected"] = 0;
        });
      }

      this.availableBottlings = result;

      this.$refs[this.model.name].updateDataSet(result);
    },
    onBottlingsTableUpdate(e) {
      this.selectedBottlings = [];

      e.forEach(i => {
        if (+i["Bottles Selected"] > +i["Bottles Remaining"])
          i["Bottles Selected"] = i["Bottles Remaining"];
        if (+i["Bottles Selected"] < 0) i["Bottles Selected"] = 0;

        if (+i["Bottles Selected"] > 0) this.selectedBottlings.push(i);
      });

      this.bottlesSelected = e.reduce((a, b) => {
        return a + parseInt(b["Bottles Selected"]);
      }, 0);
    },
    onBottlingsTableLoad(e) {
      console.log("onBottlingsTableLoad", e);
    }
  }
};
</script>

<style scope>
::v-deep .modal-xl {
  width: 85vw !important;
  max-width: 85vw !important;
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 85vw !important;
  }
}
</style>
