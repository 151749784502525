<template>
  <div class="animated fadeIn">
    <TableWrapper
      :ref="model.name"
      :parent-id="invoiceId"
      :loading="isLoading"
      actions-mode="event"
      :model="model"
      :columns="columns"
      :filter-by-column="true"
      @create="showBottlingsModal"
      @updated="onUpdate"
      @deleted="onDelete"
      @loaded="onLoad"
    ></TableWrapper>

    <WarehouseLookupModal
      ref="warehouse-lookup"
      @select="onBottlingsAdd"
    ></WarehouseLookupModal>
  </div>
</template>

<script>
import { FORM_MODE } from "@/shared/constants";
import TableWrapper from "@/components/DataView/TableWrapper";
import models from "@/models";

import WarehouseLookupModal from "@/views/Warehouse/PickLists/WarehouseLookupModal.vue";

export default {
  name: "IncomingOrderItemsTable",
  props: {
    invoiceId: {
      type: [String, Number],
      default: ""
    },
    mode: {
      type: [Number],
      default: FORM_MODE.VIEW
    },
    formData: {
      type: Object,
      default: () => {}
    }
  },
  components: { TableWrapper, WarehouseLookupModal },
  data: function() {
    return {
      isLoading: false,
      model: models.warehouse.invoiceItems,
      columns: models.warehouse.invoiceItems.entities.map(e => e.name),
      cellClasses: {
        "Bottles Diff": [
          {
            class: "bg-danger",
            condition: row => +row["Bottles Diff"] < 0
          },
          {
            class: "bg-success",
            condition: row => +row["Bottles Diff"] > 0
          }
        ],
        "Cases Diff": [
          {
            class: "bg-danger",
            condition: row => +row["Cases Diff"] < 0
          },
          {
            class: "bg-success",
            condition: row => +row["Cases Diff"] > 0
          }
        ]
      },
      customActions: [
        /*
        {
          click: this.onItemsLookupClick,
          title: "Warehouse",
          icon: "search"
        }
        */
      ]
    };
  },
  created() {
    this.initialize();
  },
  mounted() {},
  methods: {
    async initialize() {},
    getData() {
      return this.$refs[this.model.name].getData();
    },
    getDataSet() {
      return this.$refs[this.model.name].getDataSet();
    },
    updateComputedColumns() {
      let invoiceItems = this.$refs[this.model.name].getDataSet();

      for (let row of invoiceItems) {
        row["Line Cost"] = this.$helpers.round(
          (+row["Cost per Case"] * +row["Bottles"]) / 6,
          4
        );
        row["Cases"] = this.$helpers.round(+row["Bottles"] / 6, 2);
      }

      this.$refs[this.model.name].updateDataSet(invoiceItems);
    },
    onItemsLookupClick() {},
    showBottlingsModal() {
      let conditions = {
        "Warehouse ID": this.formData["Warehouse"].id,
        Warehouse: this.formData["Warehouse"].label
      };

      this.$refs["warehouse-lookup"].show(conditions);
    },
    async onBottlingsAdd(e) {
      console.log(e);
      let data = this.getDataSet();

      for (let i = 0; i < e.length; i++) {
        let r = e[i];

        let row = {
          //ID: this.$helpers.uid8(),
          ID: "",
          "Invoice ID": this.invoiceId,
          "Batch Number": r["Batch Number"],
          "Bottle Size": r["Bottle Size"],
          Bottles: +r["Bottles Selected"],
          "Bottling Number": r["Bottling Number"],
          "Finished Product": r["Finished Product"],
          "Finished Product ID": r["finished_product_id"],
          Cases: 0,
          "Cost per Case": +r["Cost per Case"],
          "Line Cost": 0,
          "Organic Status": r["Organic Status"],
          "Organic Status_ID": r["Organic Status ID"],
          Deleted: 0
        };
        data.push(row);
      }

      this.$refs[this.model.name].updateDataSet(data);

      this.updateComputedColumns();

      this.$emit("changed", this.getDataSet());
    },
    async onUpdate() {
      this.updateComputedColumns();
      this.$emit("changed", this.getDataSet());
    },

    async onDelete() {
      this.updateComputedColumns();
      this.$emit("changed", this.getDataSet());
    },
    onLoad(e) {
      this.$emit("loaded", e);
    }
  }
};
</script>

<style scoped></style>
