<template>
  <div class="animated fadeIn">
    <b-card>
      <DetailsFormWrapper
        ref="form"
        :id="id"
        :action="action"
        :model="model"
        :data="data"
        :custom-buttons="customButtons"
        @custom-click="
          name => {
            this[name]();
          }
        "
        header="Invoice"
        @loaded="onFormLoad"
      >
        <slot name="content">
          <b-tabs content-class="mt-3" justified>
            <b-tab title="Overview" active>
              <b-row>
                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['ID']"
                    :properties="entity('ID')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormDateTime
                    v-model="data['Invoice Date']"
                    :properties="entity('Invoice Date')"
                    @change="onFieldChange"
                  ></FormDateTime>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormDateTime
                    v-model="data['Shipment Date']"
                    :properties="entity('Shipment Date')"
                    @change="onFieldChange"
                  ></FormDateTime>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormSelect
                    v-model="data['Distributor']"
                    :properties="entity('Distributor')"
                    @change="onFieldChange"
                  ></FormSelect>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['PO Number']"
                    :properties="entity('PO Number')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormDateTime
                    v-model="data['PO Date']"
                    :properties="entity('PO Date')"
                    @change="onFieldChange"
                  ></FormDateTime>
                </b-col>

                <b-col lg="3" md="6" sm="6">
                  <FormSelect
                    v-model="data['Warehouse']"
                    :properties="entity('Warehouse')"
                    @change="onFieldChange"
                  ></FormSelect>
                </b-col>

                <b-col lg="3" md="6" sm="6">
                  <FormSelect
                    v-model="data['Currency']"
                    :properties="entity('Currency')"
                    @change="onFieldChange"
                  ></FormSelect>
                </b-col>
                <b-col lg="2" md="6" sm="6">
                  <FormInput
                    v-model="data['Status']"
                    :properties="entity('Status')"
                  ></FormInput>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" md="6" sm="6">
                  <FormInput
                    v-model="data['Notes']"
                    :properties="entity('Notes')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Created By']"
                    :properties="entity('Created By')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Created']"
                    :properties="entity('Created')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>

                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Modified By']"
                    :properties="entity('Modified By')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Modified']"
                    :properties="entity('Modified')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>
              </b-row>
              <hr />

              <InvoiceItemsTable
                ref="invoice-items"
                :invoice-id="id"
                :form-data="data"
                @changed="onItemsChange"
                @loaded="onItemsLoad"
              ></InvoiceItemsTable>
              <b-row>
                <b-col lg="6" md="6" sm="6"> </b-col>

                <b-col lg="6" md="6" sm="6">
                  <b-table :items="productsTotals" responsive />
                  <!--
                  <InvoiceProductsTotalsTable
                    ref="invoice-products-totals"
                    :form-data="productsTotalsData"
                  ></InvoiceProductsTotalsTable>
                  -->
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6" md="6" sm="6"> </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Discount']"
                    :properties="entity('Discount')"
                    @change="onFieldChange"
                  ></FormInput>
                </b-col>
                <b-col lg="3" md="6" sm="6">
                  <FormInput
                    v-model="data['Total']"
                    :properties="entity('Total')"
                  ></FormInput>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              :title="controls.tabs.files.title"
              v-if="$form.mode(this) !== $constants.FORM_MODE.CREATE"
            >
              <files-container
                :module-id="$route.meta.module.id"
                :entity-id="id"
                @loaded="onFileContainerLoad"
              />
            </b-tab>
          </b-tabs>
        </slot>
      </DetailsFormWrapper>
    </b-card>
  </div>
</template>
<!--   v-if="action !== 'preview'" -->
<script>
import models from "@/models";
import modelHelpers from "@/models/helpers";
import InvoiceItemsTable from "@/views/Warehouse/Invoices/InvoiceItemsTable";

export default {
  name: "InvoiceSubmissionForm",
  components: { InvoiceItemsTable },
  props: {
    id: {
      type: [String, Number],
      default: ""
    },
    action: {
      type: String,
      default: ""
    }
  },
  data: function() {
    return {
      model: models.warehouse.invoices,
      data: {},

      controls: {
        tabs: {
          files: {
            title: "Files"
          }
        }
      },
      customButtons: [
        {
          text: "Open incoming order",
          icon: "receipt",
          loading: false,
          visible: false,
          method: "openIncomingOrder",
          variant: "outline-dark"
        },
        {
          text: "Open picklist",
          icon: "receipt",
          loading: false,
          visible: false,
          method: "openPicklist",
          variant: "outline-dark"
        },
        {
          text: "Finalize invoice",
          icon: "receipt",
          loading: false,
          visible: false,
          method: "finalizeInvoice",
          variant: "outline-dark"
        }
      ],
      productsTotalsData: {},
      productsTotals: [
        {
          "Finished Product": 0,
          "Bottle Size": 0,
          Cases: 0,
          "Cost per Case": 0,
          Total: 0
        }
      ],
      entity: modelHelpers.entity
    };
  },
  computed: {},
  created() {
    this.setDefaults();
  },
  async mounted() {},
  methods: {
    async finalizeInvoice() {
      let confirm = await this.$form.showConfirmation(
        `Invoice will be finalized. Do you want to proceed?`
      );

      if (!confirm) return;

      this.customButtons[0].loading = true;

      let result = await this.$api.put(`warehouse/invoice/${this.id}`, {
        ID: this.id,
        "Status ID": 1
      });

      this.customButtons[0].loading = false;

      if (result.id) {
        this.$form.makeToastInfo("Success");

        this.$refs["form"].initialize();
      }
    },
    setDefaults() {
      this.data = modelHelpers.getEmptyEntitiesObject(this.model.entities);
    },
    /*
    entity(name) {
      return this.model.entities.find(i => i.name == name);
    },
*/
    onFormLoad(payload) {
      if (!payload) return;

      this.data = { ...this.data, ...payload };

      this.data["Warehouse"] = {
        id: payload["Warehouse ID"],
        label: payload["Warehouse"]
      };

      this.data["Distributor"] = {
        id: payload["Account ID"],
        label: payload["Account Name"]
      };

      this.data["Currency"] = {
        id: payload["Currency ID"],
        label: payload["Currency"]
      };

      this.customButtons[0].visible = this.data["Status ID"] == 0;

      this.customButtons[1].visible = this.data["Incoming Order ID"] > 0;
      this.customButtons[2].visible = this.data["Pick List ID"] > 0;
    },
    async openPicklist() {
      this.$router.push({
        name: "Pick list submission",
        params: { mode: "view", id: this.data["Pick List ID"] }
      });
    },
    async openIncomingOrder() {
      this.$router.push({
        name: "Incoming order submission",
        params: { mode: "view", id: this.data["Incoming Order ID"] }
      });
    },

    onFieldChange(e) {
      if (e.id == "discount") this.updateComputedFields();

      if (this.$form.mode(this) == this.$constants.FORM_MODE.VIEW)
        this.$refs["form"]
          .save("view")
          .then(() => this.$refs["form"].initialize());
    },
    updateProductsTotals() {
      let invoiceItems = this.$refs["invoice-items"].getDataSet();

      this.productsTotals = [];

      for (let item of invoiceItems) {
        let productIndex = this.productsTotals.findIndex(
          i =>
            i["Finished Product"] == item["Finished Product"] &&
            i["Bottle Size"] == item["Bottle Size"] &&
            i["Cost per Case"] == item["Cost per Case"]
        );

        if (productIndex < 0) {
          this.productsTotals.push({
            "Finished Product": item["Finished Product"],
            "Bottle Size": item["Bottle Size"],
            Cases: item["Cases"],
            "Cost per Case": item["Cost per Case"],
            Total: item["Line Cost"]
          });
        } else {
          this.productsTotals[productIndex]["Cases"] =
            parseFloat(this.productsTotals[productIndex]["Cases"]) +
            parseFloat(item["Cases"]);
          this.productsTotals[productIndex]["Total"] =
            parseFloat(this.productsTotals[productIndex]["Total"]) +
            parseFloat(item["Line Cost"]);

          this.productsTotals[productIndex]["Total"] = this.productsTotals[
            productIndex
          ]["Total"].toFixed(2);

          this.productsTotals[productIndex]["Cases"] = this.productsTotals[
            productIndex
          ]["Cases"].toFixed(2);
        }
      }

      this.productsTotals.sort((a, b) =>
        a["Finished Product"].localeCompare(b["Finished Product"])
      );

      /*
      this.$refs["invoice-products-totals"].updateDataSet([
        { "Finished Product": 1, "Bottle Size": 2, Total: 3 }
      ]);
      */
    },
    onItemsLoad() {
      this.updateProductsTotals();
    },
    onItemsChange() {
      this.updateComputedFields();

      if (this.$form.mode(this) == this.$constants.FORM_MODE.VIEW) {
        this.$refs["form"].save("view").then(() => {
          this.$refs["form"].initialize();
          this.$refs["invoice-items"].getData();
        });
      }

      this.updateProductsTotals();
    },
    updateComputedFields() {
      let invoiceItems = this.$refs["invoice-items"].getDataSet();

      let itemsTotal = invoiceItems.reduce((a, b) => +a + +b["Line Cost"], 0);

      this.data["Total"] = this.$helpers.round(
        itemsTotal - this.data["Discount"],
        2
      );

      this.data.items = invoiceItems;
    },
    onFileContainerLoad(count) {
      if (count > 0) this.controls.tabs.files.title = `Files (${count})`;
    }
  },
  watch: {}
};
</script>

<style scoped></style>
