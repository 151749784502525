var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('TableWrapper', {
    ref: _vm.model.name,
    attrs: {
      "parent-id": _vm.invoiceId,
      "loading": _vm.isLoading,
      "actions-mode": "event",
      "model": _vm.model,
      "columns": _vm.columns,
      "filter-by-column": true
    },
    on: {
      "create": _vm.showBottlingsModal,
      "updated": _vm.onUpdate,
      "deleted": _vm.onDelete,
      "loaded": _vm.onLoad
    }
  }), _c('WarehouseLookupModal', {
    ref: "warehouse-lookup",
    on: {
      "select": _vm.onBottlingsAdd
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }